export default [

  // Dashboard

  {
    path: '/manager/dashboard',
    name: 'Manager-Dashboard',
    component: () => import(/* webpackChunkName: "manager-dashboard" */ '@/views/manager/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    }
  },

  // Calculate Fare

  {
    path: '/manager/cal-fare',
    name: 'Manager-Cal-Fare',
    component: () => import(/* webpackChunkName: "manager-cal-fare" */ '@/views/manager/CalculateFare.vue'),
    meta: {
      requiresAuth: true,
    }
  },

  // Test Order

  {
    path: '/manager/test-order',
    name: 'Manager-Test-Order',
    component: () => import(/* webpackChunkName: "manager-test-order" */ '@/views/manager/TestOrderTime.vue'),
    meta: {
      requiresAuth: true,
    }
  },

  // Planning

  {
    path: '/manager/planning',
    name: 'Manager-Planning',
    component: () => import(/* webpackChunkName: "manager-planning" */ '@/views/manager/Planning.vue'),
    meta: {
      requiresAuth: true,
    }
  },

  // Drivers

  {
    path: '/manager/drivers',
    name: 'Manager-Drivers',
    component: () => import(/* webpackChunkName: "manager-drivers" */ '@/views/manager/Drivers.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/manager/driver/:id',
    name: 'Manager-Drivers-Single',
    component: () => import(/* webpackChunkName: "manager-driver-single" */ '@/views/manager/Driver.vue'),
    meta: {
      requiresAuth: true,
    }
  },

  // Vans

  {
    path: '/manager/vans',
    name: 'Manager-Vans',
    component: () => import(/* webpackChunkName: "manager-vans" */ '@/views/manager/Vans.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/manager/van/:id',
    name: 'Manager-Vans-Single',
    component: () => import(/* webpackChunkName: "manager-van-single" */ '@/views/manager/Van.vue'),
    meta: {
      requiresAuth: true,
    }
  },

  // Orders
  {
    path: '/manager/new-order',
    name: 'New-Orders',
    component: () => import(/* webpackChunkName: "manager-new-orders" */ '@/views/manager/NewOrder.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/manager/orders',
    name: 'Manager-Orders',
    component: () => import(/* webpackChunkName: "manager-orders" */ '@/views/manager/Orders.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/manager/order/:id',
    name: 'Manager-Orders-Single',
    component: () => import(/* webpackChunkName: "manager-order-single" */ '@/views/manager/Order.vue'),
    meta: {
      requiresAuth: true,
    }
  },

  // Trips

  {
    path: '/manager/trips',
    name: 'Manager-Trips',
    component: () => import(/* webpackChunkName: "manager-trips" */ '@/views/manager/Trips.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/manager/trip/:id',
    name: 'Manager-Trips-Single',
    component: () => import(/* webpackChunkName: "manager-trip-single" */ '@/views/manager/Trip.vue'),
    meta: {
      requiresAuth: true,
    }
  },

  // Parameters

  {
    path: '/manager/parameters',
    name: 'Manager-Parameters',
    component: () => import(/* webpackChunkName: "manager-drivers" */ '@/views/manager/Parameters.vue'),
    meta: {
      requiresAuth: true,
    }
  },
]