import store from "@/store/index.js";
import { isEmpty } from "lodash";

const beforeEach = async (to, from, next) => {
  if (to.meta.requiresAuth) {
    if (isEmpty(store.state.auth.loggedInUser)) {
      const user = await store.dispatch('auth/checkAuthToken')
      if(user.code == 1) return next({ to: '/manager/*'})
      return next({ name: 'Login' })
    } else {
      return next({ to: '/manager/*'})
    }
  } else {
    return next();
  }
};

export default beforeEach;
