<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/symbol.svg')"
          class="my-3"
          contain
          height="250"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to Woto
        </h1>

        <p class="subheading font-weight-regular">
          le plaisir de voyager ensemble
        </p>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >


        <v-row justify="center">
          <v-btn color="primary" class="px-10 py-5" to="/login">Login</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Home',

    data: () => ({

    })
  }
</script>
