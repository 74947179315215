import * as fb from '../../firebase'

const state = {
  loggedInUser: null
}

const mutations = {
  SET_LOGGED_IN_USER(state, payload) {
    state.loggedInUser = payload;
  },

}

const actions = {

  loginWithFirebase({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        const loginResult = await fb.auth.signInWithEmailAndPassword(payload.email, payload.password)


        const manager = await fb.db.collection('manager').where('uid', "==", loginResult.user.uid).limit(1).get()

        manager.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'User does not exist!' })

          let tmpObject = doc.data()
          tmpObject.id = doc.id

          commit('SET_LOGGED_IN_USER', tmpObject)
        })

        return resolve({ code: 1 })

      } catch (error) {
        return resolve(error)
      }
    })
  },

  checkAuthToken({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        fb.auth.onAuthStateChanged(async (user) => {

          if(!user) resolve({ code: 2 })
  
          const manager = await fb.db.collection('manager').where('uid', "==", user.uid).limit(1).get()
    
          manager.forEach(doc => {
            if(!doc.exists) return resolve({ code: 2, message: 'User does not exist!' })

            let tmpObject = doc.data()
            tmpObject.id = doc.id

            commit('SET_LOGGED_IN_USER', tmpObject)
          })

          return resolve({ code: 1 })

        })
      } catch (error) {
        return resolve(error)
      }
    })
  },

  logoutFromFirebase({ commit }) {
    return new Promise((resolve, reject) => {
      fb.auth.signOut().then(() => {
        const payload = null
        commit("SET_LOGGED_IN_USER", payload)
        resolve()
      }).catch((err) => {
        reject(err)
      })
    })
  }


}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
