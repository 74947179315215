import * as fb from '../../firebase'

const state = {
  driver: {}
}

const mutations = {
  SET_DRIVER(state, payload) {
    state.driver = payload
  }

}

const actions = {

  createDriver({ commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        commit("SET_DRIVER", payload)

        const driver = await fb.db.collection('driver').add(state.driver)

        if(!driver) {
          return resolve({ code: 2 })
        }

        return resolve({ code: 1 })
      } catch (error) {
        return resolve(error)
      }
    })
  },

  updateDriver({ commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let id = payload.id
        delete payload.fullName
        delete payload.id

        commit("SET_DRIVER", payload)

        const driver = await fb.db.doc(`driver/${id}`).update(state.driver)

        return resolve({ code: 1 })
      } catch (error) {
        return resolve(error)
      }
    })
  },

  addVan({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        commit("SET_DRIVER", payload)

        const driver = fb.db.doc(`driver/${payload.id}`).update({ assignedVans: payload.assignedVans })

        if(!driver) {
          return resolve({ code: 2 })
        }

        return resolve({ code: 1 })
      } catch (error) {
        return resolve(error)
      }
    })
  },

  getDrivers({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let driverArray= []

        const driver = await fb.db.collection('driver').get()

        driver.forEach(doc => {

          if(!doc.exists) return resolve({ code: 2, message: 'Drover does not exist!' })

          let tmpObject = doc.data()
          tmpObject.fullNameWithId = `${doc.data().firstName} ${doc.data().lastName} (${doc.data().customId})`
          tmpObject.id = doc.id
          tmpObject.address = doc.data().address ? doc.data().address.name : 'N/A'
          tmpObject.fullName = `${doc.data().firstName} ${doc.data().lastName}`
          tmpObject.assignedVans = [...new Set(doc.data().assignedVans)]

          // console.log(tmpObject)

          driverArray.push(tmpObject)
        })

        return resolve(driverArray)

      } catch (error) {
        return resolve(error)
      }
    })
  },

  getDriversForMacro({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let driverArray= []

        const driver = await fb.db.collection('driver').get()

        let tmpObject = {}

        driver.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Driver does not exist!' })

          tmpObject.fullName = `${doc.data().firstName} ${doc.data().lastName} (${doc.data().customId})`
          tmpObject.driverId = doc.id

          console.log(tmpObject)

          driverArray.push(tmpObject)

          // console.log(driverArray)

        })

        return resolve(driverArray)

      } catch (error) {
        return resolve(error)
      }
    })
  },

  getDriverByCustomId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        const driver = await fb.db.collection(`driver`).where('customId', '==', payload.customId).limit(1).get()

        driver.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Drover does not exist!' })

          let tmpObject = doc.data()
          tmpObject.id = doc.id
          tmpObject.fullName = `${doc.data().firstName} ${doc.data().lastName}`

          return resolve(tmpObject)
        })

        

      } catch (error) {
        return resolve(error)
      }
    })
  },

  getDriverById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {


        const doc = await fb.db.doc(`driver/${payload.id}`).get()

        if(doc.id) {

          let tmpObject = doc.data()
          tmpObject.id = doc.id
          tmpObject.fullName = `${doc.data().firstName} ${doc.data().lastName}`

          return resolve(tmpObject)
        } else {
          return resolve({ code: 2, message: 'Driver does not exist!' })
        }

      } catch (error) {
        return resolve(error)
      }
    })
  },

  async getUpcomingTrip({ commit }, payload) {
    try {
      
      const trip = await fb.db.collection('order').where('driverRef', '==', payload.id).where('tripStatus', '==', 'Scheduled').limit(1).get()

      let tripData = {}

      trip.forEach(doc => {
        tripData = doc.data()
      })

      return Promise.resolve(tripData)

    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    }
  },

  getDriversByStatus({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let driverArray= []

        const driver = await fb.db.collection('driver').where('status', '==', payload.status).get()

        driver.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Drover does not exist!' })

          let tmpObject = doc.data()
          tmpObject.fullNameWithId = `${doc.data().firstName} ${doc.data().lastName} (${doc.data().customId})`
          tmpObject.id = doc.id
          tmpObject.address = doc.data().address ? doc.data().address.name : 'N/A'
          tmpObject.fullName = `${doc.data().firstName} ${doc.data().lastName}`
          tmpObject.assignedVans = [...new Set(doc.data().assignedVans)]

          driverArray.push(tmpObject)
        })

        return resolve(driverArray)

      } catch (error) {
        return resolve(error)
      }
    })
  }


}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
