import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import van from './modules/van'
import order from './modules/order'
import driver from './modules/driver'

import * as fb from '../firebase'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    parameters: {},
  },
  mutations: {
    SET_PARAMETERS(state, payload) {
      state.parameters = payload
    }
  },
  actions: {
    async getParameters({ commit, state }) {
      let parameter = await fb.db.collection('parameters').limit(1).get()
      let tmpObject = {}
      parameter.forEach(doc => {
        tmpObject = doc.data()
        tmpObject.id = doc.id
      })
      commit('SET_PARAMETERS', tmpObject)
      return Promise.resolve(tmpObject)
    },

    async getGarageAddressList({ commit, state }) {
      let parameter = await fb.db.collection('garageAddressList').get()
      let addressArray = []
      let tmpObject = {}
      parameter.forEach(doc => {
        tmpObject = doc.data()
        tmpObject.id = doc.id
        addressArray.push(tmpObject)
      })

      return Promise.resolve(addressArray)
    },

    async updateParameters({ commit, state }, payload) {
      await fb.db.doc(`parameters/${payload.id}`).update(payload.parameters)
      let tmpObject = payload.parameters
      tmpObject.id = payload.id
      commit('SET_PARAMETERS', tmpObject)
      return Promise.resolve()
    }
  },
  modules: {
    auth,
    van,
    order,
    driver
  }
})
