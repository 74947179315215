<template>
  <v-app style="background-color: white">
    <v-main>
      <transition name="slide-fade" mode="out-in">
      <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>

import moment from 'moment'

export default {
  name: 'App',

  data: () => ({
    //
  }),

  created() {
    // console.log(moment('2021-09-21').format('x'))
  }
};
</script>

<style>

@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");

.text-number {
  font-family: "Merriweather" !important;
}

.card-header {
  background-color: #F6A02A;
}

.no-background-hover::before {
  background-color: transparent !important;
}
 
.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}
    
    
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all .2s ease;
}
    
.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}


</style>
