import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from 'moment'
import frLocale from './utils/momentLocale'
import VueCountryCode from "vue-country-code-select";

Vue.use(VueCountryCode);

moment.locale('fr', frLocale)

moment.locale('fr')

Vue.config.productionTip = false

Vue.filter('time', function (value) {
  if (!value) return ''
  let date = moment(value).format('LT')
  const time = `${date.split(':')[0]}h${date.split(':')[1]}`
  return time
})

Vue.filter('simpleDate', function (value) {
  if (!value) return ''
  return moment(value).format('L')
})

Vue.filter('fromUnix', function (value) {
  if (!value) return ''
  return moment.unix(value).format('L')
})


Vue.filter('fromUnixTime', function (value) {
  if (!value) return ''
  return moment.unix(value).format('H:mm')
})



Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
