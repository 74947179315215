import * as fb from '../../firebase'

const state = {
  van: {}
}

const mutations = {
  SET_VAN(state, payload) {
    state.van = payload
  }

}

const actions = {

  createVan({ commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        commit("SET_VAN", payload)

        const van = await fb.db.collection('van').add(state.van)

        if(!van) {
          return Promise.resolve({ code: 2 })
        }

        let newVan = payload
        newVan.id = van.id

        console.log(newVan)

        return Promise.resolve(newVan)
      } catch (error) {
        return Promise.resolve(error)
      }
    })
  },

  updateVan({ commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let id = payload.id
        delete payload.brandModel
        delete payload.id

        commit("SET_VAN", payload)

        const van = await fb.db.doc(`van/${id}`).update(state.van)

        return resolve({ code: 1 })
      } catch (error) {
        return resolve(error)
      }
    })
  },

  setMacroPlanning({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        const van = await fb.db.doc(`van/${payload.id}`).update({ assignedDrivers: payload.assignedDrivers, macroPlanning: payload.macroPlanning })

        if(!van) {
          return resolve({ code: 2 })
        }

        return resolve({ code: 1 })
      } catch (error) {
        return resolve(error)
      }
    })
  },

  getVans({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let vanArray= []

        const van = await fb.db.collection('van').get()

        van.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Van does not exist!' })

          let tmpObject = doc.data()
          tmpObject.id = doc.id
          tmpObject.brandModel = `${doc.data().brand} ${doc.data().model}`

          vanArray.push(tmpObject)
        })

        return resolve(vanArray)

      } catch (error) {
        return resolve(error)
      }
    })
  },

  getVanById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {


        const doc = await fb.db.doc(`van/${payload.id}`).get()

        if(doc.id) {

          let tmpObject = doc.data()
          tmpObject.id = doc.id
          tmpObject.brandModel = `${doc.data().brand} ${doc.data().model}`

          return resolve(tmpObject)
        } else {
          return resolve({ code: 2, message: 'Driver does not exist!' })
        }

      } catch (error) {
        return resolve(error)
      }
    })
  },

  getVansByStatus({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let vanArray= []

        const van = await fb.db.collection('van').where('status', '==', payload.status).get()

        van.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Drover does not exist!' })

          let tmpObject = doc.data()
          tmpObject.id = doc.id
          tmpObject.brandModel = `${doc.data().brand} ${doc.data().model}`

          vanArray.push(tmpObject)
        })

        return resolve(vanArray)

      } catch (error) {
        return resolve(error)
      }
    })
  },

  checkAuthToken({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        fb.auth.onAuthStateChanged(async (user) => {

          if(!user) resolve({ code: 2 })
  
          const manager = await fb.db.collection('manager').where('uid', "==", user.uid).limit(1).get()
    
          manager.forEach(doc => {
            if(!doc.exists) return resolve({ code: 2, message: 'User does not exist!' })

            let tmpObject = doc.data()
            tmpObject.id = doc.id

            commit('SET_LOGGED_IN_USER', tmpObject)
          })

          return resolve({ code: 1 })

        })
      } catch (error) {
        return resolve(error)
      }
    })
  },

  async getUpcomingTrip({ commit }, payload) {
    try {
      
      const trip = await fb.db.collection('order').where('vanRef', '==', payload.id).where('tripStatus', '==', 'Scheduled').limit(1).get()

      let tripData = {}

      trip.forEach(doc => {
        tripData = doc.data()
      })

      return Promise.resolve(tripData)

    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    }
  },


}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
