import * as fb from '../../firebase'
import moment from 'moment'
import * as dayjs from 'dayjs'
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

const state = {
  order: {}
}

const mutations = {
  SET_ORDER(state, payload) {
    state.order = payload;
  },

}

const actions = {

  createOrder({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        commit("SET_ORDER", payload)

        const order = await fb.db.collection('order').add(state.order)

        const updateDocId = await fb.db.doc(`order/${order.id}`).update({
          docId: order.id
        })

        if(!order) {
          return resolve({ code: 2 })
        }

        return resolve({ code: 1 })
      } catch (error) {
        return resolve(error)
      }
    })
  },

  async changeOrderStatus({ state, commit }, payload) {
    
    return new Promise(async (resolve, reject) => {

      if(payload.status == 'Confirmed') {
        let confirmOrder = await fb.db.doc(`order/${payload.id}`).update({
          orderStatus: 'Confirmed',
          tripStatus: 'Scheduled'
        })
        resolve(confirmOrder)
      } else {
        let confirmOrder = await fb.db.doc(`order/${payload.id}`).update({
          orderStatus: 'Cancelled',
        })
        resolve(confirmOrder)
      }

    })
  },

  async getOrdersByDateRange({ commit }, payload) {
    try {

      let orderArray = []

      const order = await fb.db.collection('order').where('pickUpDateTime', '>=', payload.start).where('pickUpDateTime', '<=', payload.end).get()

      console.log(order)

      order.forEach(doc => {
        if(doc.data().orderStatus != 'Confirmed') {
          let tmpObject = doc.data()
          tmpObject.id = doc.id

          tmpObject.trip = {
            date: `${doc.data().pickUpDateTime}`,
            time: doc.data().pickUpDateTime,
            address: doc.data().dropOffLocation.destination,
          }
    
          tmpObject.tripInfo = {
            customerName: doc.data().customerFullName,
            passengers: doc.data().passengers,
            luggages: doc.data().luggages
          }

          orderArray.push(tmpObject)
        }
      })

      return Promise.resolve(orderArray)

      
    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    }
  },

  getOrders({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let orderArray= []

        const order = await fb.db.collection('order').where('orderStatus', '!=', 'Confirmed').get()

        order.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Drover does not exist!' })

          let tmpObject = doc.data()
          tmpObject.id = doc.id

          tmpObject.trip = {
            date: `${doc.data().pickUpDateTime}`,
            time: doc.data().pickUpDateTime,
            address: doc.data().dropOffLocation.destination,
          }
    
          tmpObject.tripInfo = {
            customerName: doc.data().customerFullName,
            passengers: doc.data().passengers,
            luggages: doc.data().luggages
          }

          orderArray.push(tmpObject)
        })

        return resolve(orderArray)

      } catch (error) {
        return resolve(error)
      }
    })
  },

  getOrdersAll({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let orderArray= []

        const order = await fb.db.collection('order').get()

        order.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Drover does not exist!' })

          let tmpObject = doc.data()
          tmpObject.id = doc.id

          tmpObject.trip = {
            date: `${doc.data().pickUpDateTime}`,
            time: doc.data().pickUpDateTime,
            address: doc.data().dropOffLocation.destination,
          }
    
          tmpObject.tripInfo = {
            customerName: doc.data().customerFullName,
            passengers: doc.data().passengers,
            luggages: doc.data().luggages
          }

          orderArray.push(tmpObject)
        })

        return resolve(orderArray)

      } catch (error) {
        return resolve(error)
      }
    })
  },

  getOrdersTableByVanId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let orderArray= []

        let order

        if(payload.status == 'All') {
          order = await fb.db.collection('order').where('vanRef', '==', payload.vanId).get()
        } else {
          order = await fb.db.collection('order').where('orderStatus', '==', payload.status).where('vanRef', '==', payload.vanId).get()
        }


        order.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Drover does not exist!' })

          let tmpObject = doc.data()
          tmpObject.id = doc.id

          tmpObject.trip = {
            date: `${doc.data().pickUpDateTime}`,
            time: doc.data().pickUpDateTime,
            address: doc.data().dropOffLocation.destination,
          }
    
          tmpObject.tripInfo = {
            customerName: doc.data().customerFullName,
            passengers: doc.data().passengers,
            luggages: doc.data().luggages
          }

          orderArray.push(tmpObject)
        })

        return resolve(orderArray)

      } catch (error) {
        return resolve(error)
      }
    })
  },

  getOrdersTableByDriverId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let orderArray= []

        let order

        if(payload.status == 'All') {
          order = await fb.db.collection('order').where('driverRef', '==', payload.driverId).get()
        } else {
          order = await fb.db.collection('order').where('orderStatus', '==', payload.status).where('driverRef', '==', payload.driverId).get()
        }

        order.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Drover does not exist!' })

          let tmpObject = doc.data()
          tmpObject.id = doc.id

          tmpObject.trip = {
            date: `${doc.data().pickUpDateTime}`,
            time: doc.data().pickUpDateTime,
            address: doc.data().dropOffLocation.destination,
          }
    
          tmpObject.tripInfo = {
            customerName: doc.data().customerFullName,
            passengers: doc.data().passengers,
            luggages: doc.data().luggages
          }

          orderArray.push(tmpObject)
        })

        return resolve(orderArray)

      } catch (error) {
        return resolve(error)
      }
    })
  },

  getOrdersTableByStatus({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let orderArray= []

        const order = await fb.db.collection('order').where('orderStatus', '==', payload.status).get()

        order.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Drover does not exist!' })

          let tmpObject = doc.data()
          tmpObject.id = doc.id

          tmpObject.trip = {
            date: `${doc.data().pickUpDateTime}`,
            time: doc.data().pickUpDateTime,
            address: doc.data().dropOffLocation.destination,
          }
    
          tmpObject.tripInfo = {
            customerName: doc.data().customerFullName,
            passengers: doc.data().passengers,
            luggages: doc.data().luggages
          }

          orderArray.push(tmpObject)
        })

        console.log(payload.status, orderArray)

        return resolve(orderArray)

      } catch (error) {
        return resolve(error)
      }
    })
  },

  getTrips({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let orderArray= []

        const order = await fb.db.collection('order').where('orderStatus', '==', 'Confirmed').get()

        order.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Drover does not exist!' })

          let tmpObject = doc.data()
          tmpObject.id = doc.id

          tmpObject.trip = {
            date: `${doc.data().pickUpDateTime}`,
            time: doc.data().pickUpDateTime,
            address: doc.data().dropOffLocation.destination,
          }
    
          tmpObject.tripInfo = {
            customerName: doc.data().customerFullName,
            passengers: doc.data().passengers,
            luggages: doc.data().luggages
          }

          orderArray.push(tmpObject)
        })

        return resolve(orderArray)

      } catch (error) {
        return resolve(error)
      }
    })
  },

  async getFullOrderDataByVanId({ commit }, payload) {
    try {

      let orderArray = []

      const order = await fb.db.collection('order').where('vanRef', '==', payload.vanId).get()

      order.forEach(doc => {
        let tmpObject = doc.data()

        orderArray.push(tmpObject)
      })

      return Promise.resolve(orderArray)
      
    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    }
  },

  async getOrderByStatusDateVanId({ commit }, payload) {
    try {

      let orderArray = []

      const order = await fb.db.collection('order').where('payment.date', '==', payload.date).where('tripStatus', '==', payload.status).where('vanRef', '==', payload.vanId).get()

      order.forEach(doc => {
        let tmpObject = doc.data()

        orderArray.push(tmpObject)
      })

      return Promise.resolve(orderArray)
      
    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    }
  },

  getOrdersByVanId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let orderArray= []

        const order = await fb.db.collection('order').where('vanRef', '==', payload.vanId).get()

        order.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Orders does not exist!' })

          let tmpObject = doc.data()

          // Main trip
          let mainTripStartTime = dayjs.unix(tmpObject.pickUpDateTime).format('YYYY-MM-DD HH:mm')
          let mainTripEndTime = dayjs(mainTripStartTime).add(Number(tmpObject.estimatedTime.split(' ')[0]), 'm').format('YYYY-MM-DD HH:mm')

          // Garage to source time and rest
          let tpcDbStart = dayjs(mainTripStartTime).subtract(tmpObject.garageToSource.time + tmpObject.garageToSource.rest, 'm').format('YYYY-MM-DD HH:mm')
          let tpcDbEnd = dayjs(tpcDbStart).add(tmpObject.garageToSource.time, 'm').format('YYYY-MM-DD HH:mm')

          // After source rest
          let trPreVStart = tpcDbEnd
          let trPreVEnd = mainTripStartTime

          // Post trip Rest
          let trPostVStart = mainTripEndTime
          let trPostVEnd = dayjs(mainTripEndTime).add(tmpObject.destinationToGarage.rest, 'm').format('YYYY-MM-DD HH:mm')

          // Destination to garage
          let treVbStart = trPostVEnd
          let treVbEnd = dayjs(trPostVEnd).add(tmpObject.destinationToGarage.time, 'm').format('YYYY-MM-DD HH:mm')

          
          // tmpObject.details = {
          //   id: doc.id,
          //   customerName: doc.data().customerFullName,
          //   address: doc.data().dropOffLocation
          // }

          // console.log(moment.unix(doc.data().pickUpDateTime).format('YYYY-MM-DD HH:mm'))
          // tmpObject.start = moment.unix(`${doc.data().pickUpDateTime}`).format('YYYY-MM-DD HH:mm')
          // tmpObject.end = moment.unix(doc.data().pickUpDateTime).add(2, 'hours').format('YYYY-MM-DD HH:mm');

          let itemsToPush = [
            {
              name: 'TPC - DB',
              start: tpcDbStart,
              end: tpcDbEnd,
              color: 'pink lighten-3'
            },
            {
              name: 'TR-Pre-V',
              start: trPreVStart,
              end: trPreVEnd,
              color: 'yellow lighten-1'
            },
            {
              name: 'main',
              details: {
                customerName: doc.data().customerFullName,
                driverName: `${doc.data().driverFullName} (${doc.data().driverCustomId})`,
                source: doc.data().pickUpLocation.name,
                destination: doc.data().dropOffLocation.name,
                orderId: doc.data().customId
              },
              start: mainTripStartTime,
              end: mainTripEndTime,
              color: 'green lighten-3'
            },
            {
              name: 'TR-Post. V',
              start: trPostVStart,
              end: trPostVEnd,
              color: 'yellow lighten-1'
            },
            {
              name: 'TRe - VB',
              start: treVbStart,
              end: treVbEnd,
              color: 'blue-grey lighten-4'
            }
          ]

          orderArray.push(...itemsToPush)
        })

        return resolve(orderArray)

      } catch (error) {
        return resolve(error)
      }
    })
  },

  getOrdersByDriverId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        let orderArray= []

        const order = await fb.db.collection('order').where('driverRef', '==', payload.driverId).limit(3).get()

        order.forEach(doc => {
          if(!doc.exists) return resolve({ code: 2, message: 'Van does not exist!' })

          let tmpObject = doc.data()
          tmpObject.id = doc.id

          orderArray.push(tmpObject)
        })

        return resolve(orderArray)

      } catch (error) {
        return resolve(error)
      }
    })
  },


  async getOrderById({ state, commit }, payload) {
    let doc = await fb.db.doc(`order/${payload.id}`).get()
    return new Promise((resolve, reject) => {
      if(doc.id) {
        let tmpObject = {}
        tmpObject = doc.data()
        tmpObject.id = doc.id
        tmpObject.trip = {
          date: moment.unix(doc.data().pickUpDateTime).format('L'),
          time: moment.unix(doc.data().pickUpDateTime).format('H:mm'),
          address: doc.data().dropOffLocation,
        }

        tmpObject.tripInfo = {
          customerName: doc.data().customerFullName,
          passengers: doc.data().passengers,
          luggages: doc.data().luggages
        }
        resolve(tmpObject)
      } else {
        reject("Order not found")
      }
    })
  },


}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
